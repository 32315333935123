import { Avatar, Box, Button, Card, CardContent, Chip, Divider, Grid, Input, Modal, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useMember } from "../util/MemberstackUtil";
import { useMemberstack } from "@memberstack/react";

export function EditMemberPanel(row) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const memberstack = useMemberstack();
    const [authMember, loading] =  useMember()

    const member = row.row.data ?? ''

    const {
      id,
      lastLogin,
      planConnections,
      customFields,
      profileImage,
      auth
    } = member

    const { email } = auth

    const {
      "account-credit": accountCredit, 
      address, 
      artists, 
      "authors-books": authorsBooks, 
      birthday, 
      career, 
      checkin, 
      company, 
      "delivery-notes": deliveryNotes, 
      details, 
      "dietary-restrictions": dietaryRestrictions, 
      "directory-opt-out": directoryOptOut, 
      "exciting-membership-aspects": excitingMembershipAspects, 
      "first-name": firstName, 
      gender, 
      "interests-hobbies": interestsHobbies, 
      "job-title": jobTitle, 
      "last-name": lastName, 
      "member-start-date": memberStartDate, 
      "mobile-number": mobileNuber, 
      musicians, 
      occasions, 
      "omage-notes": omageNotes, 
      passions, 
      "pet-peeves": petPeeves, 
      "places-to-visit": placesToVisit, 
      prefix, 
      profession, 
      restaurants, 
      setpassword, 
      "snowy-day-order": snowyDayOrder, 
      "spouse-birthday": spouseBirthday, 
      "spouse-partner": spousePartner, 
      "spouse-partner-birthday": spousePartnerBirthday, 
      "spouse-partner-dietary": spousePartnerDietary, 
      "spouse-partner-first-name": spousePartnerFirstName, 
      "spouse-partner-last-name": spousePartnerLastName, 
      suffix, 
      "sunny-day-order": sunnyDayOrder, 
      "where-from": whereFrom, 
    } = customFields
    
    const efPrimary = [

      'prefix', 
      'first-name', 
      'last-name', 
      'suffix', 
      'gender', 
      'birthday', 
      'member-start-date', 
      'mobile-number',
      'job-title', 
      'company',
      'address', 
      'dietary-restrictions', 
      'delivery-notes', 
    ]

    const efSpouse = [
      'spouse-partner-first-name', 
      'spouse-partner-last-name', 
      'spouse-birthday',
      'spouse-email',
      'spouse-phone', 
      'spouse-partner-dietary', 
    ]

    const efInternal = [
      'account-credit', 
      'details', 
      'omage-notes', 
      'directory-opt-out', 
    ]

    const efPersonal = [
      'artists', 
      'authors-books', 
      'career', 
      'exciting-membership-aspects', 
      'interests-hobbies',      
      'musicians', 
      'occasions', 
      'passions', 
      'pet-peeves', 
      'places-to-visit',     
      'profession', 
      'restaurants', 
      'snowy-day-order', 
      'sunny-day-order', 
      'where-from', 
    ]


      // call api to change checkin status
  const setMember = async (id, data) => {

    var res = await fetch(`https://api.goldenskep.com/api/member/${id}`, {
      method: 'PATCH',
      headers: {
        'X-Member-Id': authMember?.id
      },
      body: JSON.stringify(data)
    })
  
    var response = await res.json();
    console.log(response)
    return response
  
  }


    const PlanChip = ({value}) => {
      var color = 'success'
        if (value === 'Administrator') { color = 'secondary'} else if (value === 'Honorary') { color = 'primary' } else if (value === 'Onboarding' || value === 'Honorary Onboarding') { color = 'warning'}

        return <Chip label={value.toUpperCase()} color={color} />
    }

    const FieldList = (i) => {
      const input = i.i
      var title = input?.split('-') ?? title
      title = title?.join(' ').toUpperCase() ?? title
      return (
        <Stack spacing={{ xs: 1, sm: 2 }} direction="row" flexWrap="wrap" sx={{width: '100%'}}>
            <Stack sx={{width: '100%'}}>
              <TextField
                fullWidth
                label={title}
                variant="standard"
                onChange={(v) => {customFields[input] = v.target.value; console.log(input, customFields[input], member); }}
                defaultValue={customFields[input]}
              />
            </Stack>  
        </Stack>
      )
    }


    console.log('opened member', member)

    return (
      <Box sx={{ backgroundColor:'#e8e8e8', padding: 2}}>
        <Box sx={{backgroundColor: '#fff', padding: 4, borderRadius: 6, marginBottom: 2}}>
          <Typography variant="h4">Member Details</Typography>
          <br></br>
          <Divider/>
          <br></br>
          <Stack direction='row' spacing={3} divider={<Divider orientation="vertical" flexItem />} >
            <Item>Member ID: {id}</Item>
            <Item>Email: {email}</Item>
            <Item>Last Login: {new Date(lastLogin).toLocaleString('en-US', {timeZone: 'America/New_York',})}</Item>
            <Stack spacing={1} direction='row'>
              {planConnections.map((p) => {
                return (               
                    <PlanChip value={p.planName}/>
                )
              })}
            </Stack>
            <Button variant="contained" onClick={e => navigator.clipboard.writeText(`https://www.goldenskep.com/public/membership?referral=` + member.id)}>Copy Referral Link</Button>
          </Stack>
        </Box>    
        <Box sx={{backgroundColor: '#fff', padding: 4, borderRadius: 6}}>
          <Stack direction='column' spacing={3}>
              <Typography variant="h5">Primary Member Settings</Typography>
              <Divider/>
              {efPrimary.map(i => <FieldList i={i}/>)}

              <br></br>
              <Typography variant="h5">Spouse Settings</Typography>
              <Divider/>
              {efSpouse.map(i => <FieldList i={i}/>)}

              <br></br>
              <Typography variant="h5">Personal Questionnaire</Typography>
              <Divider/>
              {efPersonal.map(i => <FieldList i={i}/>)}

              <br></br>
              <Typography variant="h5">Internal Settings</Typography>
              <Divider/>
              {efInternal.map(i => <FieldList i={i}/>)}

              <Button variant="contained" onClick={(m) => setMember(id, member)}>Save Member</Button>
          </Stack>
        </Box>        
      </Box>
    )
  }

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text,
  }));
