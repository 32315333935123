import { useMemberstack } from "@memberstack/react"
import { useState, useEffect } from "react"


export function useMember() {
    const memberstack = useMemberstack()
    const [member, setMember] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => {
                setMember(member)
                setLoading(false)
            })
    }, [])

    return [member, loading]
}