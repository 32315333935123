import { Box, Chip, Divider, FormControl, InputLabel, Select, Typography, useMediaQuery } from "@mui/material"
import MenuItem from '@mui/material/MenuItem';
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro"
import { useCallback, useEffect, useState } from "react"
import { OrderDetailsPanel } from "../components/OrderDetailPanel"
import { MemberstackProtected, useAuth } from "@memberstack/react"
import { useMember } from "../util/MemberstackUtil"
import ResponsiveAppBar from "../components/Navbar"
import Login from "./Login"



export function OrderManagement() {
  
  const [member, loading] =  useMember()
  const { isLoggedIn } = useAuth();

  const apiRef = useGridApiRef()

  const [open, setOpen] = useState(false);

  const statuses = [
    { title: 'RECEIVED', color: '#00A7E1' }, 
    { title: 'EN ROUTE', color: '#e8e295' }, 
    { title: 'ON PREM', color: '#4ECDC4' }, 
    { title: 'PACKED', color: '#7EE081' },
    { title: 'UPSTAIRS', color: '#F0B67F' },
    { title: 'FULFILLED', color: '#62A87C' },
    { title: 'PARTIAL', color: '#ff2b4b' },
    { title: 'BILLED', color: '#D9E5D6' },
    { title: 'REFUNDED', color: '#ff2b4b' },
  ]


  var hiddenCols = {
    data: false,
  }

  var desktop = true

  if (useMediaQuery('(max-width:700px)')) {
    console.log('going mobile')
    hiddenCols = { 
        data: false,
        acceptedOn: false,
        id: false, 

     }
    desktop = false
  }


  const [multiStatus, setMultiStatus] = useState(null)

  const cc = new ColumnComponent()

   // details panels
   const getDetailPanelContent = useCallback(
    ({ row }) => <OrderDetailsPanel row={row} rows={rows} callback={dpCallback} authID={member?.id} setRows={setRows} />,
    [],
  );


  const dpCallback = (updated, row, memberId) => {
    console.log(updated, row)
    row.purchasedItems.map(item => item.productId === updated.productId ? updated : item)
    console.log('newrow', row)
    UpdateOrder(memberId, row)
    return updated
  }

  const [rows, setRows] = useState([])

  // call aws gateway to get key
  const aws = async (params) => {
    var orders = await AllOrders(member?.id)
    console.log(orders)
    setRows(orders)
  }

  const MultiStatusSelector = () => {
    return (
      <FormControl>
        <InputLabel shrink={false}>Change multiple...</InputLabel>
        <Select
          sx={{width: 200}}
          value={multiStatus}
          onChange={(e) => {
            const selectedRows = apiRef.current.getSelectedRows()
            const updatedRows = rows.map(row => selectedRows.get(row.id) ? { ...row, status: e.target.value } : row)
            setMultiStatus(e.target.value)
            setRows(updatedRows)
            UpdateMultipleOrders(member?.id, updatedRows)
            console.log(selectedRows, e)
            setMultiStatus(null)
            
          }}
        >
        {statuses.map((s) => <MenuItem value={s.title}>{cc.filledChip(s.title, s.color)}</MenuItem>)}
        </Select>
      </FormControl>
    )
      
  }


  useEffect(() => {
    aws().catch(e => console.log(e))
  }, [member, isLoggedIn])

  const columns = [
    {
      field: 'id',
      headerName: 'Order ID',
      width: 70
    },
    {
      field: 'acceptedOn',
      headerName: 'Order Date',
      valueFormatter: (o) => new Date(o.value ).toLocaleString('en-US', {timeZone: 'America/New_York'}).split(',')[0],
      width: 100
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      editable: true,
      type: 'singleSelect',
      renderCell: (o) => {
        const status = statuses.find((s) => s.title === o.value) ?? {title: 'Unknown', color:'primary'}

        return cc.filledChip(status.title, status.color)
      },
      renderEditCell: (o) => cc.select(o, statuses, open, (e) => {
        const { value } = e.target
        const { id } = o

        apiRef.current.setEditCellValue({ id, field: 'status', value, debounceMs: 200 })
        apiRef.current.stopCellEditMode({ id, field: 'status' });
        
      })
    },
    {
      field: 'email',
      headerName: 'Member Email',
      flex: 1,
      valueGetter: (o) => o?.row?.customerInfo?.email ?? ''
    },
    {
      field: 'data',
      headerName: 'data',
      flex: 1,
      valueGetter: (o) => JSON.stringify(o?.row)
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      valueGetter: (o) => {
        const arr = o?.row?.customData ?? ''
        if (!arr) { return ''}
        const string = arr[1].textArea
        return parseFloat(string.split('Total: ')[1]).toFixed(2)
      },
      valueFormatter: (o) => `$${o.value}`
    }
]

  if (loading) return <Box><ResponsiveAppBar/><Typography padding={2} width='100vw' textAlign='center'>Loading...</Typography></Box>

  if (member && isLoggedIn) {
    return (
      <Box>
        <ResponsiveAppBar desktop={desktop}/>
        <MemberstackProtected onUnauthorized={<Typography variant="h1">Unauthorized</Typography>} allow={{plans: ["pln_administrator-vr2c70zxs", "pln_super-admin-ja5w0mwp"]}}>
        
        <Box sx={{padding: 3}}>
        <Typography variant="h4">Order Management</Typography>
        <br></br>
        <Divider/>
        <br></br>
        <MultiStatusSelector/>
        <br></br>
        <br></br>
        <DataGridPro 
          {...{columns, rows}}
          apiRef={apiRef}
          autoHeight
          checkboxSelection={true}
          initialState={{
            columns: {
              columnVisibilityModel: hiddenCols
            },
          }}
          getDetailPanelContent={getDetailPanelContent}
          processRowUpdate={(updated, original) => UpdateOrder(member.id, updated)}
          onProcessRowUpdateError={(e) => console.log(e)}
          onCellClick={(params, event, details) => {
            if (params.field === 'status' && params.cellMode === 'view') {
              params.open = true
              apiRef.current.startCellEditMode({ id: params.id, field: 'status' });
              setOpen(true)
            }
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        </Box>
      </MemberstackProtected>
      </Box>
      
    )
  }

  if (!loading && !isLoggedIn && !member){window.location.replace('/')}

}


export class ColumnComponent {
  constructor() {
    this.filledChip = (value, color) => <Chip label={value} sx={{backgroundColor: color}}></Chip>
    this.outlinedChip = (value, color) => <Chip label={value} color={color} variant="outlined"></Chip>
    this.select = (row, statuses, open, handleChange) => {
      
      console.log(row)
      return (
      <FormControl fullWidth>
        <Select
          defaultValue={statuses[0]}
          onChange={handleChange}
          open={open}
        >
        {statuses.map((s) => <MenuItem value={s.title}>{this.filledChip(s.title, s.color)}</MenuItem>)}
        </Select>
      </FormControl>
    )}
  }
}



async function AllOrders(authID) {
  var res = await fetch(`https://api.goldenskep.com/api/orders?memberId=&status=`, {
      method: 'GET',
      headers: {
        'X-Member-Id': authID
      }
    })
    var json = await res.json()
    for (let order of json.orders) {order.id = order.orderId}
    return json.orders
}


async function UpdateOrder(authID, order) {
  console.log(order)
  fetch(`https://api.goldenskep.com/api/orders/${order.orderId}`, {
      method: 'POST',
      headers: {
        'X-Member-Id': authID
      },
      body: JSON.stringify({order})
  })
  return order
}

async function UpdateMultipleOrders(authID, orders) {
  await Promise.allSettled(orders.map(order => UpdateOrder(authID, order)))
}