import { MemberstackProtected, useAuth } from "@memberstack/react";
import { Avatar, Box, Chip, Divider, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material"
import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { DataGridPro } from "@mui/x-data-grid-pro"
import cmList from "../components/cmList"
import { Check, Close, Edit } from "@mui/icons-material"
import Login from "./Login";
import { useMember } from "../util/MemberstackUtil";
import ResponsiveAppBar from "../components/Navbar";
import { LiveDetailPanel } from "../components/LiveDetailPanel copy";

export default function Live () {

  const [member, loading] =  useMember()
  const { isLoggedIn } = useAuth();
  


  const [columns, setColumns] = useState([])

  const [rows, setRows] = useState([])
  
  //MS secret key init
  const [allMembers, setAllMembers] = useState([])

  // Responsiveness

  var columnstop = {id: false, data: false, lastname: false}
  var columnsbottom = {id: false, data: false, lastin: false, lastname: false}


  // visible columns
  if (useMediaQuery('(max-width:700px)')) {
    console.log('going mobile')
    columnstop = {id: false, data: false, lastname: false, spouse: false, email: false, plan: false}
    columnsbottom = {id: false, data: false, lastin: false, lastname: false, spouse: false, email: false, plan: false}
  }


  // details panels
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <LiveDetailPanel row={row} />,
    [],
  ); 
  
  
  const loop = async () => {

    var res = await fetch(`https://api.goldenskep.com/api/members`, {
      headers: {
        'X-Member-Id': member?.id
      }
    })
    var json = await res.json()

    var members = json.members
    var checkedIn = await cmList(members, 'date', setStatus)
    var list = await cmList(members, 'all', setStatus)

    setRows(checkedIn)
    setAllMembers(list)
  }


  // call api to change checkin status
  const setStatus = async (id, mode) => {
  
    
    var date
    var exited
    if (mode === 'in') {date = new Date()} else if (mode === 'out') {exited = new Date()} else if (mode === 'clear') {exited = null; date = null}

    console.log(member?.id)

    var res = await fetch(`https://api.goldenskep.com/api/member/${id}`, {
      method: 'PATCH',
      headers: {
        'X-Member-Id': member?.id
      },
      body: JSON.stringify( { 
        customFields: {checkin: null},
        metaData: { lastin: date, exited: exited } 
      } )
    })
  
    var response = await res.json();
    console.log(response)
    return response
  
  }
  
  
  
    useEffect(() => {
      loop().catch((e) => console.log(e))
      const interval = setInterval(() => {
        loop().catch((e) => console.log(e))
      }, 2000)

      return () => clearInterval(interval)
    }, [member, loading])
  
    useEffect(() => {

      if (!member) {return}

      setColumns([
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'lastin',
          headerName: 'Arrived',
          width: 90,
          valueGetter: (o) => {
            var time = o.row.lastin
            if (time.staff < time.user && time.staff > time.exited) { return time.user }
            else if (time.exited > time.staff) { return time.exited }
            return time.staff
          },
          valueFormatter: (o) => o.value.date,
          renderCell: (o) => {

            function isValidDate(d) {
              return d instanceof Date && !isNaN(d);
            }
    
            var time = o.row.lastin
            var val = {date: time.staff, status: 'Staff'}
            if (time.staff < time.user && time.staff > time.exited) {val = {date: time.user, status: 'User'}}
            else if (time.exited > time.staff) {val =  {date: time.exited, status: 'Exited'}}
            else if (!isValidDate(time.staff)) {val = {date: time.user, status: 'User'}}
    
            var date = new Date(val.date) ?? ''
            var formatted = String(date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})) ?? ''
            if (val.status === 'User') {
              return (
                <Box sx={{backgroundColor: 'yellow', color: 'black'}}>
                  <Typography>{formatted}</Typography>
                </Box>
              )
            } else if (val.status === 'Staff') {
              return (
                <Box sx={{backgroundColor: 'green', color: 'white'}}>
                  <Typography>{formatted}</Typography>
                </Box>
              )
            } else if (val.status === 'Exited') {
              return (
                <Box sx={{backgroundColor: 'red', color: 'white'}}>
                  <Typography>{formatted}</Typography>
                </Box>
              )
            }
    
            
          },
          editable: false,
        },
        {
          field: 'img',
          headerName: '',
          width: 60,
          editable: false,
          renderCell: (o) => {
              var a
            if (o.row.img) {
              a = <Avatar src={o.row.img} />
            } else {
              a = <Avatar {...stringAvatar(o.row.firstlast)} />
            }
            return a
          }
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          editable: false,
        },
        {
          field: 'lastname',
          headerName: 'lastname',
          flex: 1,
          editable: false,
        },
        {
          field: 'spouse',
          headerName: 'Spouse',
          flex: 1,
          editable: false,
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          editable: false,
        },
        {
          field: 'plan',
          headerName: 'Plan',
          flex: 1,
          filterable: true,
          type: 'string',
          editable: false,
          renderCell: (o) => {
            var color = 'success'
            if (o.value === 'Administrator') { color = 'secondary'} else if (o.value === 'Honorary') { color = 'primary' } else if (o.value === 'Onboarding' || o.value === 'Honorary Onboarding') { color = 'warning'}
    
            return <Chip label={o.value.toUpperCase()} color={color} />
          }
        },
        {
          field: 'actions',
          type: 'actions',
          flex: 1,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<Check />}
              label="In"
              onClick={() => {
                console.log(params)
                setStatus(params.id, 'in')
              }}
            />,
            <GridActionsCellItem
              icon={<Close />}
              label="Out"
              onClick={() => {
                console.log(params)
                setStatus(params.id, 'out')
              }}
            />,
            <GridActionsCellItem
              icon={<Edit />}
              label="Open"
              href={`#`}
              target="_parent"
            />,
          ],
        },
        {
          field: 'data',
          headerName: 'data',
          flex: 1,
          editable: false,
          valueGetter: (o) => JSON.stringify(o.value),
        },
      ])
    }, [member])

    if (loading) return <Box><ResponsiveAppBar/><Typography padding={2} width='100vw' textAlign='center'>Loading...</Typography></Box>

    if (member && isLoggedIn) {
        
      return (

        <Box>
          <ResponsiveAppBar/>
            <MemberstackProtected onUnauthorized={<Typography variant="h1">Unauthorized</Typography>} allow={{plans: ["pln_administrator-vr2c70zxs", "pln_staff-4s5u0az4", "pln_super-admin-ja5w0mwp"]}}>
              <Box sx={{padding: '2%'}}>
              <br></br>
              <Typography variant="h4">On Premise</Typography>
              <Divider/>
              <br></br>
              <DataGridPro 
                {...{rows, columns}}
                autoHeight
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                initialState={{
                  columns: {
                    columnVisibilityModel: columnstop
                  },
                  sorting: {
                    sortModel: [
                      {
                        field: 'lastin',
                        sort: 'desc',
                      },
                    ],
                  },
                }}
                components={{
                  toolbar: GridToolbar,
                  NoRowsOverlay: () => (
                    <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                        <Stack height="auto" alignItems="center" justifyContent="center">
                            Loading from database... I think... is the place empty??
                        </Stack>  
                        <Typography variant="h4"><Skeleton /></Typography>          
                    </Box>
                  
                )}}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
              <br></br>
              <br></br>
              <Typography variant="h4">All Members</Typography>
              <Divider/>
              <br></br>
              <DataGridPro 
                columns={columns}
                rows={allMembers}
                autoHeight
                pagination
                unstable_headerFilters
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                initialState={{
                  columns: {
                    columnVisibilityModel: columnsbottom
                  },
                  sorting: {
                    sortModel: [
                      {
                        field: 'lastname',
                        sort: 'asc',
                      },
                    ],
                  },
                  filter: {
                    filterModel: {
                      items: [
                        {
                          field: 'plan',
                          operator: 'contains',
                          value: 'Golden',
                        },
                        {
                          field: 'plan',
                          operator: 'equals',
                          value: 'Honorary',
                        },
                      ],
                      logicOperator: 'And',
                    },
                  },
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                components={{
                  toolbar: GridToolbar,
                  NoRowsOverlay: () => (
                    <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                        <Stack height="auto" alignItems="center" justifyContent="center">
                            Loading from database... This should only take a few seconds.
                        </Stack>  
                        <Typography variant="h4"><Skeleton /></Typography>          
                    </Box>
                  
                ),
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
              <h2>Help Section</h2>
              <h4>Top Table</h4>
              <p>
                <span style={{backgroundColor: 'yellow'}}>Yellow</span> time means a member has checked themselves in, so they are likely nearby. 
                <br></br><span style={{backgroundColor: 'green', color: 'white'}}>Green</span> means a staff member has officially checked the member in.
                <br></br><span style={{backgroundColor: 'red', color: 'white'}}>Red</span> Means they have left the event, marked by front of house. Will stay on screen for 6 minutes to allow time for staff to be informed.
                <br></br>Use the plus symbol on the left to see more information about a member. The button on the far right will open the edit member page.
              </p>
              <h4>Bottom Table</h4>
              <p>Use this table to search for active members and to manually check in a member.</p>
              </Box>
            </MemberstackProtected>
        </Box>

       

  
        
      )
    }

    if (!loading && !isLoggedIn && !member){window.location.replace('/')}

}


function stringAvatar(name) {
return {
    sx: {
    bgcolor: '#b39759',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
};
}






  