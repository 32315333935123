import { MemberstackProtected, useAuth, useMemberstack } from "@memberstack/react";
import { Avatar, Box, Button, Chip, Divider, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material"
import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { DataGridPro } from "@mui/x-data-grid-pro"
import cmList from "../components/cmList"
import { Check, Close, Edit } from "@mui/icons-material"
import Login from "./Login";
import { useMember } from "../util/MemberstackUtil";
import ResponsiveAppBar from "../components/Navbar";
import { EditMemberPanel } from "../components/EditMemberPanel";

export default function Members () {

  const memberstack = useMemberstack();
  const [member, loading] =  useMember()
  const { isLoggedIn } = useAuth();

  const [columns, setColumns] = useState([
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'img',
      headerName: '',
      width: 60,
      editable: false,
      renderCell: (o) => {
          var a
        if (o.row.img) {
          a = <Avatar src={o.row.img} />
        } else {
          a = <Avatar {...stringAvatar(o.row.firstlast)} />
        }
        return a
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: false,
    },
    {
      field: 'lastname',
      headerName: 'lastname',
      flex: 1,
      editable: false,
    },
    {
      field: 'spouse',
      headerName: 'Spouse',
      flex: 1,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: false,
    },
    {
      field: 'plan',
      headerName: 'Plan',
      flex: 1,
      editable: false,
      renderCell: (o) => {
        var color = 'success'
        if (o.value === 'Administrator') { color = 'secondary'} else if (o.value === 'Honorary') { color = 'primary' } else if (o.value === 'Onboarding' || o.value === 'Honorary Onboarding') { color = 'warning'}

        return <Chip label={o.value.toUpperCase()} color={color} />
      },
    },
    {
      field: 'data',
      headerName: 'data',
      flex: 1,
      editable: false,
      valueGetter: (o) => JSON.stringify(o.value),
    },
  ])

  const queryStrings = new URLSearchParams(window.location.search)

  const [memberFiltered, setMemberFiltered] = useState(queryStrings.get("member"))

  const [rows, setRows] = useState([])

  // Responsiveness

  var columnsbottom = {id: false, data: false, lastin: false, lastname: false}


  // visible columns
  if (useMediaQuery('(max-width:700px)')) {
    console.log('going mobile')
    columnsbottom = {id: false, data: false, lastin: false, lastname: false, spouse: false, email: false, plan: false}
  }


  // details panels
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <EditMemberPanel row={row} />,
    [],
  );
  
  
  
  const getMembers = async () => {

    var res = await fetch(`https://api.goldenskep.com/api/members`, {
      headers: {
        'X-Member-Id': member?.id
      }
    })
    var json = await res.json()

    var members = json.members
    var list = await cmList(members, 'all')

    setRows(list)
  }
  
  
  
    useEffect(() => {
      getMembers().catch((e) => console.log(e))
    }, [member])
  
    if (loading) return <Box><ResponsiveAppBar/><Typography padding={2} width='100vw' textAlign='center'>Loading...</Typography></Box>

    if (member && isLoggedIn) {
        
      return (

        <Box>
          <ResponsiveAppBar/>
            <MemberstackProtected onUnauthorized={<Typography variant="h1">Unauthorized</Typography>} allow={{plans: ["pln_administrator-vr2c70zxs", "pln_super-admin-ja5w0mwp"]}}>
              <Box sx={{padding: '2%'}}>
              <Typography variant="h4">Edit Members</Typography>
              <Divider/>
              <br></br>
              <Button variant="contained" onClick={()=> window.location.search = ''}>Clear Filters</Button>
              <br></br>
              <br></br>
              <DataGridPro 
                {...{columns, rows}}
                autoHeight
                pagination
                unstable_headerFilters
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                initialState={{
                  columns: {
                    columnVisibilityModel: columnsbottom
                  },
                  sorting: {
                    sortModel: [
                      {
                        field: 'lastname',
                        sort: 'asc',
                      },
                    ],
                  },
                  filter: {
                    filterModel: {
                      items: [{ field: 'id', operator: 'contains', value: memberFiltered ?? '' }],
                    },
                  },
                }}
                components={{
                  toolbar: GridToolbar,
                  NoRowsOverlay: () => (
                    <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                        <Stack height="auto" alignItems="center" justifyContent="center">
                            Loading from database... This should only take a few seconds.
                        </Stack>  
                        <Typography variant="h4"><Skeleton /></Typography>          
                    </Box>
                  
                ),
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
              <h2>Help Section</h2>
              <p>Use the plus sign on the left to open the member editor</p>
              </Box>
            </MemberstackProtected>
        </Box>

       

  
        
      )
    }

    if (!loading && !isLoggedIn && !member){window.location.replace('/')}

}


function stringAvatar(name) {
return {
    sx: {
    bgcolor: '#b39759',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
};
}






  