import { LoggedIn, LoggedOut, SignInModal, useAuth } from "@memberstack/react";
import { Box, CircularProgress, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMember } from "../util/MemberstackUtil";
import { useEffect } from "react";
import Live from "./Live";
import ResponsiveAppBar from "../components/Navbar";

export default function Login() {

    //const memberstack = useMemberstack();
    const [member, loading] = useMember()
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate()

    
    useEffect(() => {
        console.log(member)
        if (member && isLoggedIn) {
            //return navigate("/admin/live")
        }
    }, [member])

    if (loading) {
        return <Stack direction='row' alignContent='center' alignItems='center' ><CircularProgress /></Stack>
    }

    if (member) {window.location.href = '/members'}


    return (
        <Box>
            <LoggedOut>
                <SignInModal/>
            </LoggedOut>
        </Box>
    )
}