import { MemberstackProtected, useAuth } from "@memberstack/react";
import { Avatar, Box, Button, Chip, Divider, LinearProgress, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material"
import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { DataGridPro } from "@mui/x-data-grid-pro"
import cmList from "../components/cmList"
import { Check, Close, Edit } from "@mui/icons-material"
import Login from "./Login";
import { useMember } from "../util/MemberstackUtil";
import ResponsiveAppBar from "../components/Navbar";
import { LiveDetailPanel } from "../components/LiveDetailPanel copy";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function Reporting () {

  const [adminMember, loading] =  useMember()
  const { isLoggedIn } = useAuth();
  
  const [allMembers, setAllMembers] = useState([])

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'lastin',
      headerName: 'Arrived',
      width: 90,
      valueGetter: (o) => {
        var time = o.row.lastin
        if (time.staff < time.user && time.staff > time.exited) { return time.user }
        else if (time.exited > time.staff) { return time.exited }
        return time.staff
      },
      valueFormatter: (o) => o.value.date,
      renderCell: (o) => {

        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }

        var time = o.row.lastin
        var val = {date: time.staff, status: 'Staff'}
        if (time.staff < time.user && time.staff > time.exited) {val = {date: time.user, status: 'User'}}
        else if (time.exited > time.staff) {val =  {date: time.exited, status: 'Exited'}}
        else if (!isValidDate(time.staff)) {val = {date: time.user, status: 'User'}}

        var date = new Date(val.date) ?? ''
        var formatted = String(date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})) ?? ''
        if (val.status === 'User') {
          return (
            <Box sx={{backgroundColor: 'yellow', color: 'black'}}>
              <Typography>{formatted}</Typography>
            </Box>
          )
        } else if (val.status === 'Staff') {
          return (
            <Box sx={{backgroundColor: 'green', color: 'white'}}>
              <Typography>{formatted}</Typography>
            </Box>
          )
        } else if (val.status === 'Exited') {
          return (
            <Box sx={{backgroundColor: 'red', color: 'white'}}>
              <Typography>{formatted}</Typography>
            </Box>
          )
        }

        
      },
      editable: false,
    },
    {
      field: 'img',
      headerName: '',
      width: 60,
      editable: false,
      renderCell: (o) => {
          var a
        if (o.row.img) {
          a = <Avatar src={o.row.img} />
        } else {
          a = <Avatar {...stringAvatar(o.row.firstlast)} />
        }
        return a
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: v => {
        console.log(v)
        if (v.row.report.primary) {
          return (
            <Chip label={v.value} color="success"></Chip>
          )
        } else {
          return (
            <Chip label={v.value} color="error"></Chip>
          )
        }
      },
      editable: false,
    },
    {
      field: 'lastname',
      headerName: 'lastname',
      flex: 1,
      editable: false,
    },
    {
      field: 'spouse',
      headerName: 'Spouse',
      flex: 1,
      renderCell: v => {
        console.log(v)
        if (v.row.report.secondary) {
          return (
            <Chip label={v.value} color="success"></Chip>
          )
        } else {
          return (
            <Chip label={v.value} color="error"></Chip>
          )
        }
      },
      editable: false,
    },
    {
      field: 'guests',
      headerName: 'Guests',
      valueGetter: v => v.row.report.guests,
      width: 100,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: false,
    },
    {
      field: 'plan',
      headerName: 'Plan',
      flex: 1,
      filterable: true,
      type: 'string',
      editable: false,
      renderCell: (o) => {
        var color = 'success'
        if (o.value === 'Administrator') { color = 'secondary'} else if (o.value === 'Honorary') { color = 'primary' } else if (o.value === 'Onboarding' || o.value === 'Honorary Onboarding') { color = 'warning'}

        return <Chip label={o.value.toUpperCase()} color={color} />
      }
    },
    {
      field: 'totalParty',
      headerName: 'Total Party',
      flex: 1,
      valueGetter: v => {
        const report = v.row.report
        var party = report.guests
        if (report.primary) {party++}
        if (report.secondary) {party++}
        console.log(party)
        return party
      },
      editable: false,
    },
    {
      field: 'data',
      headerName: 'data',
      flex: 1,
      editable: false,
      valueGetter: (o) => JSON.stringify(o.value),
    },
  ]

  const [rows, setRows] = useState([])
  
  const [date, setDate] = useState()
  const changeDate = (v) => {setDate(v); console.log(v)}
  const [loadingBar, setLoadingBar] = useState(0)

  // Responsiveness

  var columnstop = {id: false, data: false, lastname: false}
  var columnsbottom = {id: false, data: false, lastin: false, lastname: false}


  // visible columns
  if (useMediaQuery('(max-width:700px)')) {
    console.log('going mobile')
    columnstop = {id: false, data: false, lastname: false, spouse: false, email: false, plan: false}
    columnsbottom = {id: false, data: false, lastin: false, lastname: false, spouse: false, email: false, plan: false}
  }


  const initialMemberFetch = async () => {

    var res = await fetch(`https://api.goldenskep.com/api/members`, {
      headers: {
        'X-Member-Id': adminMember?.id
      }
    })
    var json = await res.json()

    var members = json.members
    var list = await cmList(members, 'all')

    setAllMembers(list)
  }


  const reportGenerator = async () => {    

    var membersCopy = []
    var progress = 0
    var totalMembers = allMembers.length

    let dateCode = new Date(date)
    const dayID = new Date(dateCode.getTime() - 14400000).toISOString().split('T')[0]

    console.log('REPORTING FOR', allMembers, 'on ', dayID)

    for (let currMember of allMembers) {
      console.log(membersCopy)
      //console.log('GOING FOR ', currMember, 'using', adminMember?.id)
      try {
        var res = await fetch(`https://api.goldenskep.com/api/member/${currMember.id}`, {
          headers: {
            'X-Member-Id': adminMember?.id
          }
        })
        var response = await res.json()

        const history = response.data.json?.history ?? null

        console.log(history)

        if (history) {
          for (let historicalDate of Object.keys(history)) {
            if (dayID === historicalDate) {
              console.log('was present', history[historicalDate])
              currMember.report = history[dayID]
              console.log(currMember)
              membersCopy.push(currMember)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }

      progress++
      var percent = (progress/totalMembers) * 100
      console.log(percent)
      setLoadingBar(percent)

    }

    setRows(membersCopy)
    console.log('DONE')
  }

  
  useEffect(() => {
    initialMemberFetch().catch((e) => console.log(e))
  }, [adminMember, loading])
  

  
    if (loading) return <Box><ResponsiveAppBar/><Typography padding={2} width='100vw' textAlign='center'>Loading...</Typography></Box>

    if (adminMember && isLoggedIn) {
        
      return (

        <Box>
          <ResponsiveAppBar/>
            <MemberstackProtected onUnauthorized={<Typography variant="h1">Unauthorized</Typography>} allow={{plans: ["pln_administrator-vr2c70zxs", "pln_staff-4s5u0az4", "pln_super-admin-ja5w0mwp"]}}>
              <Box sx={{padding: '2%'}}>
                <Stack direction='row' spacing={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker onChange={changeDate}/>
                  </LocalizationProvider>
                  <Button variant="contained" onClick={reportGenerator}>SUBMIT</Button>
                </Stack>
                <br></br>
                <br></br>
              <Typography variant="h4">Report for {(String(`${String(date?.$d?? '').split(' ')[0]} ${date?.$M ?? -1 +1}/${date?.$D??'0'}/${date?.$y??'0'}`)) ?? ''}</Typography>
              <br></br>
              <Divider/>
              <br></br>
              <LinearProgress variant="determinate"  value={loadingBar} />
              <br></br>
              <DataGridPro 
                columns={columns}
                rows={rows}
                autoHeight
                initialState={{
                  columns: {
                    columnVisibilityModel: columnsbottom
                  },
                  sorting: {
                    sortModel: [
                      {
                        field: 'lastname',
                        sort: 'asc',
                      },
                    ],
                  },
                }}
                components={{
                  toolbar: GridToolbar,
                  NoRowsOverlay: () => (
                    <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                        <Stack height="auto" alignItems="center" justifyContent="center">
                            Please Select a Date from above and hit submit. This process can take some time as we have to check each member.
                        </Stack>  
                        <Typography variant="h4"><Skeleton /></Typography>          
                    </Box>
                  
                ),
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
              </Box>
            </MemberstackProtected>
        </Box>

       

  
        
      )
    }

    if (!loading && !isLoggedIn && !adminMember){window.location.replace('/')}

}


function stringAvatar(name) {
return {
    sx: {
    bgcolor: '#b39759',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
};
}






  