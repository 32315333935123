import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MemberstackProvider } from "@memberstack/react"

const config = {
    publicKey: "pk_16eb1b29193d8c1942a2",
  }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MemberstackProvider config={config}>
        <App />
    </MemberstackProvider>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
