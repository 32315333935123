import { Avatar, Box, Button, Card, CardContent, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, Modal, Slider, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useMember } from "../util/MemberstackUtil";




export function LiveDetailPanel(row) {

    const [value, setValue] = React.useState(0);
    const [adminMember, loading] =  useMember()

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const member = row.row.data ?? ''

    console.log('OPEN MEMBER', member)

    // Guest Variables

    const [whoPrimary, setWhoPrimary] = useState(false)
    const [whoSecondary, setWhoSecondary] = useState(false)
    const [whoGuests, setWhoGuests] = useState(0)
    const [json, setJSON] = useState({})

    const changeGuests = (v) => {setWhoGuests(v.target.value)}
    const changePrimary = (v) => {setWhoPrimary(v.target.checked)}
    const changeSecondary = (v) => {setWhoSecondary(v.target.checked)}


  // change guests
  const setWho = async () => {

    let dateCode = new Date()
    const dayID = new Date(dateCode.getTime() - 14400000).toISOString().split('T')[0]

    json[dayID] = {primary: whoPrimary, secondary: whoSecondary, guests: whoGuests}

    var res = await fetch(`https://api.goldenskep.com/api/member/${member?.id}`, {
      method: 'PATCH',
      headers: {
        'X-Member-Id': adminMember?.id
      },
      body: JSON.stringify({json: {history: json}})
    })
  
    var response = await res.json();
    console.log(response)
    return response
  
  }


  const getGuests = async () => {

    try {
      var res = await fetch(`https://api.goldenskep.com/api/member/${member?.id}`, {
        method: 'GET',
        headers: {
          'X-Member-Id': adminMember?.id
        }
      })
    
      var response = await res.json();
      const history = response.data.json.history

      console.log(history)

      const keys = Object.keys(history)

      let dateCode = new Date()
      const dayID = new Date(dateCode.getTime() - 14400000).toISOString().split('T')[0]

      for (let key of keys) {
        if (dayID === key) {
          const match = history[key]
          setWhoPrimary(match.primary)
          setWhoSecondary(match.secondary)
          setWhoGuests(match.guests)

          console.log('SET STATUS', whoPrimary, whoSecondary, whoGuests)
        }
      }

      console.log(keys)

      return response
    } catch (error) {
      console.log('error getting json', error)
    }
  
  }


  useMemo(() => {
      getGuests()
    }, [loading])



    

    const {
      id,
      lastLogin,
      planConnections,
      customFields,
      profileImage,
      auth
    } = member

    const { email } = auth

    const {
      "account-credit": accountCredit, 
      address, 
      artists, 
      "authors-books": authorsBooks, 
      birthday, 
      career, 
      checkin, 
      company, 
      "delivery-notes": deliveryNotes, 
      details, 
      "dietary-restrictions": dietaryRestrictions, 
      "directory-opt-out": directoryOptOut, 
      "exciting-membership-aspects": excitingMembershipAspects, 
      "first-name": firstName, 
      gender, 
      "interests-hobbies": interestsHobbies, 
      "job-title": jobTitle, 
      "last-name": lastName, 
      "member-start-date": memberStartDate, 
      "mobile-number": mobileNumber, 
      musicians, 
      occasions, 
      "omage-notes": omageNotes, 
      passions, 
      "pet-peeves": petPeeves, 
      "places-to-visit": placesToVisit, 
      prefix, 
      profession, 
      restaurants, 
      setpassword, 
      "snowy-day-order": snowyDayOrder, 
      "spouse-birthday": spouseBirthday, 
      "spouse-partner": spousePartner, 
      "spouse-partner-birthday": spousePartnerBirthday, 
      "spouse-partner-dietary": spousePartnerDietary, 
      "spouse-partner-first-name": spousePartnerFirstName, 
      "spouse-partner-last-name": spousePartnerLastName, 
      "spouse-partner-email": spousePartnerEmail, 
      "spouse-partner-phone": spousePartnerPhone, 
      suffix, 
      "sunny-day-order": sunnyDayOrder, 
      "where-from": whereFrom, 
    } = customFields

    const efPersonal = [
      'artists', 
      'authors-books', 
      'career', 
      'exciting-membership-aspects', 
      'interests-hobbies',      
      'musicians', 
      'occasions', 
      'passions', 
      'pet-peeves', 
      'places-to-visit',     
      'profession', 
      'restaurants', 
      'snowy-day-order', 
      'sunny-day-order', 
      'where-from', 
    ]

    const FieldList = (i) => {
      const input = i.i
      var title = input?.split('-') ?? title
      title = title?.join(' ').toUpperCase() ?? title
      return (
        <Box sx={{textAlign: 'left', }}>
          <Typography variant="h6">{title}:</Typography>
          <Typography>{customFields[input]}</Typography>
          <br></br>
          <Divider />
          <br></br>
        </Box>
      )
    }


    var spouse = {}

    if (spousePartnerFirstName) {
      spouse.nameField = `& ${spousePartnerFirstName} ${spousePartnerLastName}`
      spouse.bdayTitle = `${spousePartnerFirstName}'s Birthday`
      spouse.dietaryTitle = `${spousePartnerFirstName}'s Dietaries`
    }

    const PlanChip = ({value}) => {
      var color = 'success'
        if (value === 'Administrator') { color = 'secondary'} else if (value === 'Honorary') { color = 'primary' } else if (value === 'Onboarding' || value === 'Honorary Onboarding') { color = 'warning'}

        return <Chip label={value.toUpperCase()} color={color} />
    }


    return (
      <Box sx={{backgroundColor: '#c2c2c2'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs sx={{backgroundColor: 'white'}} value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
            <Tab label="Primary Details" {...a11yProps(0)} />
            <Tab label="Internal Notes" {...a11yProps(1)} />
            <Tab label="Personal Details" {...a11yProps(2)} />
          </Tabs>
        </Box>



        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={1}>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Avatar src={member.profileImage} sx={{ width: 250, height: 250 }}></Avatar>
                  <BasicModal img={profileImage}></BasicModal>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {prefix} {firstName} {lastName} {suffix}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {spouse.nameField}
                  </Typography>
                  <Typography variant="body1">
                    {career}
                  </Typography>
                  <Typography variant="body2">
                    {member.auth.email}
                    <br></br>
                    <a href={`tel:${mobileNumber}`}>{mobileNumber}</a>
                  </Typography>
                  <br></br>
                  <PlanChip value={member.planConnections[0].planName ?? ''}></PlanChip>
                  <br></br><br></br>
                  <Typography variant="h6" component="div">
                    Account Credit
                  </Typography>
                  <br></br>
                  <Typography variant="body2">
                    ${accountCredit}
                  </Typography>
                  <br></br>
                  <Divider></Divider>
                  <br></br>
                  <Button variant="outlined" href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${member.auth.email}`}>Send Email</Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                <FormGroup>
                  <FormControlLabel control={<Checkbox key={Math.random()} defaultChecked={whoPrimary} onChange={changePrimary}/>} label={firstName} />
                  <FormControlLabel control={<Checkbox key={Math.random()} defaultChecked={whoSecondary} onChange={changeSecondary}/>} label={spousePartnerFirstName} />
                  <Typography>Guests</Typography>
                  <Typography sx={{fontSize:60}}>{whoGuests}</Typography>
                  <Slider
                    defaultValue={0}
                    step={1}
                    value={whoGuests}
                    onChange={changeGuests}
                    marks
                    min={0}
                    max={8}
                    slots={{ valueLabel: "None" }}
                  />
                  <br></br>
                  <Button variant="contained" onClick={setWho}>Save</Button>
                </FormGroup>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {firstName}'s Birthday
                  </Typography>
                  <Typography variant="h5" component="div">
                    {birthday}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {bdayCalc(birthday)}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {spouse.bdayTitle}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {spouseBirthday}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {spousePartnerEmail}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {spousePartnerPhone}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {bdayCalc(spouseBirthday)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {firstName}'s Dietaries
                  </Typography>
                  <Typography variant="h5" component="div">
                    {dietaryRestrictions}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {spouse.dietaryTitle}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {spousePartnerDietary}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Address
                  </Typography>
                  <Typography variant="h5" component="div">
                    {address}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CustomTabPanel>




        <CustomTabPanel value={value} index={1}>
        <Grid container spacing={1}>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Omage Notes
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {omageNotes}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Details
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {details}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CustomTabPanel>



        <CustomTabPanel value={value} index={2}>
          <Box sx={{backgroundColor: '#fff', padding: 3, borderRadius: 4}}>
            <Typography variant="h5">Personal Questionnaire</Typography>
            <Divider/>
            <br></br>
            {efPersonal.map(i => <FieldList i={i}/>)}
          </Box>
        </CustomTabPanel>
      </Box>
    )
  }

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text,
  }));

  const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);

  function bdayCalc(date) {
    const bday = new Date(date)
    const today = new Date()
    const age = (today-bday)/(1000*60*60*24*365)
    const between = dayOfYear(bday) - dayOfYear(today)
    const days = Math.abs(between)
    var calc;
    if (between > 0) { calc = 'in ' + String(days) + ' day(s)  -  ' + String(Math.floor(age)) + ' years old.'}
    else if (between < 0) { calc = 'was ' + String(days) + ' day(s) ago  -  '  + String(Math.floor(age)) + ' years old.'}
    if (between === 0) { calc = 'TODAY'}

    return calc
    
  }


  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '88vw',
    height: '88vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  
  function BasicModal({img}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        }}>
        <Button size="large" variant="contained" sx={{marginTop: 1}} onClick={handleOpen}>Fullscreen</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} onClick={handleClose}>
            <img src={img} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img>
          </Box>
        </Modal>
      </div>
    );
  }
