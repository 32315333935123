import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, Stack, Switch, TextField, Typography } from "@mui/material";
import ResponsiveAppBar from "../components/Navbar";
import { MemberstackProtected, useAuth } from "@memberstack/react";
import Login from "./Login";
import { useEffect, useState } from "react";
import { useMember } from "../util/MemberstackUtil";
import { DateTimePicker, LocalizationProvider, StaticDateTimePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';


export function Notifications() {


    const [member, loading] =  useMember()
  const { isLoggedIn } = useAuth();

  const [submitLabel, setSubmitLabel] = useState("Send")
  const [scheduleState, setScheduleState] = useState()
  const [testState, setTestState] = useState(false)
  

  const [title, setTitle] = useState(null)
  const [message, setMessage] = useState(null)
  const [url, setUrl] = useState(null)
  const [scheduleDate, setScheduleDate] = useState()

  
  
  const aws = async () => {

    //todo
    
  }

  


    const handleScheduleState = (e) => {
        if (e.target.checked) {
          setScheduleState(true)
          setSubmitLabel("Schedule Notification")
        } else {
          setSubmitLabel("Send")
          setScheduleState(false)
        }
    }
  
  
    useEffect(() => {
      aws().catch((e) => console.log(e))
    }, [member, loading])
  
    useEffect(() => {

      if (!member) {return}

    }, [member])

    if (loading) return <Box><ResponsiveAppBar/><Typography padding={2} width='100vw' textAlign='center'>Loading...</Typography></Box>

    if (member && isLoggedIn) {
        
      return (

        <Box>
          <ResponsiveAppBar/>
            <MemberstackProtected onUnauthorized={<Typography variant="h1">Unauthorized</Typography>} allow={{plans: ["pln_super-admin-ja5w0mwp"]}}>

              <Box sx={{padding: '2%'}}>
                <Typography variant="h4">Notification Sender</Typography>
                <br></br>
                <Divider/>
                <br></br>
                <FormControl fullWidth>
                    <Stack direction="column" spacing={2} maxWidth={900}>
                        <FormControlLabel control={<Switch defaultChecked={false} checked={testState} onChange={e => setTestState(e.target.checked)} />} label="TEST MODE" />
                        <TextField id="outlined-basic" label="Heading" autoComplete="off" variant="outlined" onChange={e => setTitle(e.target.value)}/>
                        <TextField id="outlined-basic" label="Subheading" autoComplete="off" variant="outlined" multiline rows={4} onChange={e => setMessage(e.target.value)}/>
                        <TextField id="outlined-basic" sx={{display: 'none'}} label="Link (Does Nothing)" autoComplete="off" variant="outlined" onChange={e => setUrl(e.target.value)}/>
                        <FormControlLabel control={<Switch defaultChecked={false} checked={scheduleState} onChange={e => handleScheduleState(e)} />} label="Schedule Sending" />
                        <DateTime
                          scheduleState={scheduleState}
                          callback={ e => {
                            if (!e?.$d) return
                            setScheduleDate(e.$d)
                          }}
                          value={scheduleDate}/>
                        <Button checked variant="contained" onClick={e => SubmitNotification(title, message, url, scheduleDate, member?.id, testState)}>{submitLabel}</Button>
                    </Stack>
                </FormControl>
              </Box>

            </MemberstackProtected>
        </Box>

       

  
        
      )
    }

    if (!loading && !isLoggedIn && !member){window.location.replace('/')}
}


async function SubmitNotification(title, message, url, scheduleDate, authId, sandbox) {

  if (!message) {
    alert("A message is required.")
    return
  }

  const now = new Date()
  const future = new Date(scheduleDate)

  var diff = Math.floor((future - now) / 1000)

  if (diff < 0 || !diff) { diff = 0 }

  const payload = {
    title, message, url, sandbox,
    delaySeconds: diff
  }

  //payload.sandbox = !window.location.hostname.includes("goldenskep.com")
  

  console.log(payload)
  
  const res = await fetch("https://api.goldenskep.com/notification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Member-Id": authId
    },
    body: JSON.stringify(payload)
  })

  const awsRes = await res.json() ?? "error"
  if(awsRes?.startDate) alert("Success")
  else alert("Failed")

  window.location.reload()
  
}


function DateTime(params) {

  const {scheduleState, callback, value } = params

  if (scheduleState) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateTimePicker ampmInClock disablePast minDate={new AdapterDayjs(new Date())} minutesStep={5} yearsPerRow={4} value={value} onChange={callback} sx={{maxWidth: '400px'}}/>
      </LocalizationProvider>
    )
  } else {
    return
  }
}