import React, { useEffect } from "react"
import {
  Routes,
  Route,
  BrowserRouter,
  useNavigate
} from "react-router-dom"
import './App.css';
import ReactGA from 'react-ga';

import { LicenseInfo } from '@mui/x-license-pro';
import Orders from "./pages/Orders";
import { OrderManagement } from "./pages/OrderManagement";
import Live from "./pages/Live";
import Login from "./pages/Login";
import { LoggedIn, LoggedOut, SignInModal, useAuth } from "@memberstack/react";
import ResponsiveAppBar from "./components/Navbar";
import { useMember } from "./util/MemberstackUtil";
import Members from "./pages/Members";
import Applications from "./pages/Applications";
import { Notifications } from "./pages/Notifications";
import Reporting from "./pages/Reporting";

LicenseInfo.setLicenseKey('37dc94c65fbb348da71d210a58a696a5Tz03Mjk0MSxFPTE3MjM5MTU3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');



function App() {

  document.title = "Golden Skep Admin Panel"

  ReactGA.initialize('G-MX0H4MN8Z2');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/live" element={<Live />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/members" element={<Members />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/order-management" element={<OrderManagement />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/notifications" element={<Notifications />} />
        </Routes>
      </BrowserRouter>
    
  );
}

export default App;

function Home() {

  const [member, loading] = useMember()
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {navigate('/members')}
  }, [isLoggedIn])

  if (!isLoggedIn) {
    return <Login/>
  }

  
  
}


