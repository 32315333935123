import { MemberstackProtected, useAuth } from "@memberstack/react";
import { Avatar, Box, Divider, Typography } from "@mui/material"
import { GridActionsCellItem, GridLogicOperator, GridToolbar } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro"
import { Email, Phone } from "@mui/icons-material"
import Login from "./Login";
import { useMember } from "../util/MemberstackUtil";
import ResponsiveAppBar from "../components/Navbar";
import { ApplicationDetailPanel } from "../components/ApplicationDetails";
import { ColumnComponent } from "./OrderManagement";
import { debounce } from "lodash";

export default function Applications () {

  const [member, loading] =  useMember()
  const { isLoggedIn } = useAuth();

  const apiRef = useGridApiRef()
  const [open, setOpen] = useState(false);

  const statuses = [
    { title: 'RECEIVED', color: '#00A7E1' }, 
    { title: 'SOLICITED', color: '#6cf08f' }, 
    { title: 'IN REVIEW', color: '#e8e295' }, 
    { title: 'PENDING APPROVAL', color: '#4ECDC4' }, 
    { title: 'APPROVED', color: '#7EE081' },
    { title: 'ARTIST', color: '#b260e6' },
    { title: 'IMPORTED TO MEMBERSTACK', color: '#F0B67F' },
    { title: 'WAITLIST', color: '#ff2b4b' },
    { title: 'REJECTED', color: '#ff2b4b' },
  ]

  const cc = new ColumnComponent()
  


  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'submittedOn',
      headerName: 'Submitted On',
      width: 110,
      valueGetter: v => new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }).format(new Date(parseInt(v.id)))
    },
    {
      field: 'avatar',
      headerName: '',
      renderCell: v => <Avatar src={v.row.form.photo}/>
    },
    {
      field: 'tier',
      headerName: 'Tier',
      width: 160,
      valueGetter: v => v.row.form.tier
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      editable: true,
      type: 'singleSelect',
      valueOptions: statuses.map(s => s.title),
      renderCell: (o) => {
        const status = statuses.find((s) => s.title === o.value) ?? {title: 'Unknown', color:'primary'}

        return cc.filledChip(status.title, status.color)
      },
      renderEditCell: (o) => cc.select(o, statuses, open, (e) => {
        const { value } = e.target
        const { id } = o

        apiRef.current.setEditCellValue({ id, field: 'status', value, debounceMs: 200 })
        apiRef.current.stopCellEditMode({ id, field: 'status' });
        
      })
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: false,
      valueGetter: v => [v.row.form.prefix ?? '', v.row.form['first-name'], v.row.form['last-name'], v.row.form.suffix ?? '', ].join(' ')
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: false,
      valueGetter: v => v.row.form.email
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      editable: false,
      valueGetter: v => v.row.form['mobile-number']
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Phone />}
          label="In"
          onClick={() => {
            window.open(`tel:${params.row.form['mobile-number']}`)
          }}
        />,
        <GridActionsCellItem
          icon={<Email />}
          label="In"
          onClick={(e) => {
            console.log(e)
            //window.open(`mailto:${params.row.form.email}`)
          }}
        />,
      ],
    },
    {
      field: 'data',
      headerName: 'data',
      flex: 1,
      editable: false,
      valueGetter: (o) => JSON.stringify(o.value),
    },
  ]

  const [rows, setRows] = useState([])


  // details panels
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <ApplicationDetailPanel row={row} authID={member?.id} callback={dpCallback}/>,
    [],
  );

  let valueTimeout
  const dpCallback = (newRow, authID) => {
    clearTimeout(valueTimeout)
    apiRef.current.updateRows([newRow])
    valueTimeout = setTimeout(() => {console.log(newRow); setAppStatus(authID, newRow)}, 2000)
  } 
  
  
  const aws = async () => {

    const apps = await fetchApps(member?.id).catch(e=>console.log(e))

    var list = await processApps(apps)
   
    setRows(list)
    
  }


  
  
  
    useEffect(() => {
      aws().catch((e) => console.log(e))
    }, [member, loading])
  
    useEffect(() => {

      if (!member) {return}

    }, [member])

    if (loading) return <Box><ResponsiveAppBar/><Typography padding={2} width='100vw' textAlign='center'>Loading...</Typography></Box>

    if (member && isLoggedIn) {
        
      return (

        <Box>
          <ResponsiveAppBar/>
            <MemberstackProtected onUnauthorized={<Typography variant="h1">Unauthorized</Typography>} allow={{plans: ["pln_administrator-vr2c70zxs", "pln_super-admin-ja5w0mwp"]}}>
              <Box sx={{padding: '2%'}}>
              <Typography variant="h4">Membership Applications</Typography>
              <br></br>
              <Divider/>
              <br></br>
              <DataGridPro 
                {...{columns, rows}}
                autoHeight
                apiRef={apiRef}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                processRowUpdate={(updated, original) => {console.log(updated); return setAppStatus(member?.id, updated)}}
                onProcessRowUpdateError={(e) => console.log(e)}
                onCellClick={(params, event, details) => {
                  if (params.field === 'status' && params.cellMode === 'view') {
                    params.open = true
                    apiRef.current.startCellEditMode({ id: params.id, field: 'status' });
                    setOpen(true)
                  }
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                      data: false
                    },
                  },
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'asc' }],
                  },
                  pagination: { paginationModel: { pageSize: 25 } },
                  filter: {
                    filterModel: {
                      items: [
                        { id: 1, field: 'status', operator: 'not', value: 'REJECTED' },
                        { id: 2, field: 'status', operator: 'not', value: 'IMPORTED TO MEMBERSTACK' }
                      ],
                      logicOperator: GridLogicOperator.And,
                    },
                  },
                }}
                slots={{
                  toolbar: GridToolbar
                }}
              />
              </Box>
            </MemberstackProtected>
        </Box>

       

  
        
      )
    }

    if (!loading && !isLoggedIn && !member){window.location.replace('/')}

}


function processApps(apps) {

  var newRows = apps.map(app => {
    const { applicationId, data } = app
    const json = JSON.parse(data.S)
    const { status, referrer, form } = json
   

    return { id: applicationId.S, status, referrer, form }
  })

  return newRows
}

async function setAppStatus(authID, data) {
  console.log(data)
  fetch(`https://api.goldenskep.com/api/submit-application/${data.id}`, {
      method: 'POST',
      headers: {
        'X-Member-Id': authID
      },
      body: JSON.stringify(data)
  })
  return data
}

async function fetchApps(authID) {
  var res = await fetch(`https://api.goldenskep.com/api/applications/`, {
      headers: {
        'X-Member-Id': authID
      }
    })
    
    var json = await res.json()

    return json.applications

}