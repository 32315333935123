export default async function cmList(members, mode, setStatus) {
    var memberList = []
        
    for (let member of members) {
        var cf = member.customFields
        var date = new Date(member.metaData.lastin) ?? ''
        var exited = new Date(member.metaData.exited) ?? ''
        var checkin = new Date(cf.checkin) ?? ''
        let cm = {}
    
        cm.data = member
        cm.id = member.id
        cm.email = member.auth.email
        cm.name = [cf["prefix"], cf["first-name"], cf["last-name"], cf["suffix"]].join(' ')
        cm.firstlast = [cf["first-name"], cf["last-name"]].join(' ')
        cm.lastname = cf["last-name"]
        cm.lastin = {user: checkin, staff: date, exited: exited}
        
        try {
        cm.plan = member.planConnections[0].planName
        } catch (error) {}
        cm.phone = cf['mobile-phone'] ?? ''
        
    
        cm.spouse = [cf["spouse-partner-first-name"], ' ', cf["spouse-partner-last-name"]].join(' ') ?? ''
        cm.img = member.profileImage ?? ''
        cm.dietary = cf["dietary-restrictions"] ?? ''
        cm.birthday = cf["birthday"] ?? ''
        cm.sunny = cf["sunny-day-order"] ?? ''
        cm.snowy = cf["snowy-day-order"] ?? ''
    
    
    
        var onprem = false
    
        // see if either staff or user checked in
        if (await PastTime(date, 9) || await PastTime(checkin, 9)) { onprem = true }
        // make sure they weren't just checked out
        if (await PastTime(exited, .1, '>')) { setStatus(member.id, 'clear') }
        
    
        if (mode === 'date' && onprem) {
        memberList.push(cm)
        } else if (mode === 'all' && member.planConnections.length > 0) {
        memberList.push(cm)
        }
    }
    
    return memberList
    }

      
  
const PastTime = (date, past, mode) => {
    // past is amount desired in hours
    const hour = 1000 * 60 * 60 * past;
    const hourago = Date.now() - hour;
    if (mode === '>') {
        return date < hourago;
    } else {
        return date > hourago;
    }
    }