import { Circle } from "@mui/icons-material"
import { Box, Button, Chip, Divider, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material"
import { useGridApiContext, useGridSelector } from "@mui/x-data-grid"
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, gridDetailPanelExpandedRowsContentCacheSelector, useGridApiRef } from "@mui/x-data-grid-pro"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"



export default function Orders() {
    const apiRef = useGridApiRef()

    const [queryParameters] = useSearchParams()
    const authID = queryParameters.get("member") ?? queryParameters.get("id") ?? 'mem'

    // Responsiveness

  let columnsVis = {member: false}

  // visible columns
  if (useMediaQuery('(max-width:1500px)')) {
    console.log('going mobile')
    columnsVis.purchasedItemsCount = false
  }
  if (useMediaQuery('(max-width:1200px)')) {
    console.log('going mobile')
    columnsVis.purchasedItemsCount = false
    columnsVis.acceptedOn = false
  }
  if (useMediaQuery('(max-width:800px)')) {
    console.log('going mobile')
    columnsVis.customTotal = false
  }
  if (useMediaQuery('(max-width:500px)')) {
    console.log('going mobile')
    columnsVis.id = false
  }



    const [col, setCol] = useState([
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
              <CustomDetailPanelToggle id={params.id} value={params.value} />
            ),
            width: 90,

        },
        {
            field: 'id',
            headerName: 'Order #',
            width: 90,
        },
        {
            field: 'purchasedItemsCount',
            headerName: 'Items',
            width: 60,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            flex: 1,
            renderCell: (o) => {
                var color
                var text
                if (o.value  === 'fulfilled') { text = 'Completed'; color = 'success' } else if (o.value === 'refunded') { text = 'Refunded'; color = 'error'} else { text = 'In Progress'; color = 'warning'}
                
                return <Chip icon={<Circle color={color} />} label={text.toUpperCase()} variant="outlined" sx={{color: 'white'}}/>
            },
        },
        {
            field: 'purchasedItems',
            headerName: 'Products',
            width: 90,
            flex: 1,
            valueFormatter: (o) => {
                var items = []

                for (let item of o.value) {
                    items.push(item.productName)
                }

                if (items.length > 1) {
                    return (items[0] + ' and ' + String(items.length - 1) + ' others')
                } else {
                    return items[0]
                }
            },
        },
        {
            field: 'member',
            headerName: 'Member ID',
            width: 90,
            flex: 1,
        },
        {
            field: 'customTotal',
            headerName: 'Total',
            width: 90,
            flex: 1,
            valueGetter: (o) => {
                const val = o.value ?? ''
                var num = val.split('Total: ')[1] ?? ''
                return parseFloat(num).toFixed(2)
            },
            valueFormatter: (o) => {
                if (o.value) {
                    return `$${o.value}`
                } else {
                    return ''
                }
            },
        },
        {
            field: 'acceptedOn',
            headerName: 'Date',
            type: 'date',
            width: 90,
            flex: 1,
        },
    ])

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <OrdersPanel row={row} />,
        [],
      );


    const [row, setRows] = useState([])

    async function fetchOrders() {
        var res = await fetch(`https://4jf4dmskeh.execute-api.us-east-1.amazonaws.com/prod/webflow/orders/${authID}`)
        var json = await res.json()
        console.log(json)
        return json
    }

    async function processOrders() {
        var orders = await fetchOrders()

        for (let order of orders){
            order.id = order.orderId
            

            for (let item of order.customData) {
                if (item.name === 'Member ID') {
                    order.member = item.textInput
                } else if (item.name === 'Total') {
                    order.customTotal = item.textArea
                }
            }
        }
        console.log(orders)
        setRows(orders)
    }
    

    

    useEffect(() => {
        processOrders()
    }, [])

    return (
        <Box sx={{backgroundColor: '#1a1a1a'}}>
            <DataGridPro
                sx={{
                    color: 'white',
                    border: 2,
                    borderColor: '#1a1a1a',
                }}
                columns={col}
                rows={row}
                autoHeight
                slots={{
                    //columnHeaders: () => null,
                    footer: () => null,
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                            <Stack height="auto" alignItems="center" justifyContent="center">
                                Loading from database...
                            </Stack>  
                            <Typography variant="h4"><Skeleton /></Typography>          
                        </Box>
                    )
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: columnsVis
                      },
                      sorting: {
                        sortModel: [
                          {
                            field: 'lastname',
                            sort: 'asc',
                          },
                        ],
                      },
                      pagination: { paginationModel: { pageSize: 25 } },
                }}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
            />
        </Box>
    )

}


function OrdersPanel(row) {
    console.log(row.row)
    var items = []

    for (let item of row.row.purchasedItems) {
        items.push(item.variantName.split(': ')[1] + ' of ' + item.productName)
    }

    return (
        <Box sx={{backgroundColor: '#1a1a1a', padding: "20px"}} padding={20}>
            <Typography variant="h5">Details for Order {row.row.id}</Typography>
            <br></br>
            <Divider color="white"/>
            <br></br>
            <Typography>{items.join(', ')}</Typography>
            <Typography variant="h6">{row.row.customTotal}</Typography>

            <br></br>
            <Divider color="white"/>
            <br></br>
            <Typography variant="p5">More Details Coming Soon...</Typography>
        </Box>
    )
}


function CustomDetailPanelToggle(props) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();
  
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );
  
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);
  
    return (
    <Button variant="outlined">
        Open
      </Button>
    );
  }