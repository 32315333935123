import { Avatar, Box, Button, Card, CardContent, Chip, Divider, Grid, Modal, Stack, Tab, Tabs, TextField, Typography, debounce } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useMember } from "../util/MemberstackUtil";
import { saveAs } from "file-saver";


export function ApplicationDetailPanel(params) {

    const { row, authID, callback } = params

    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [member, loading] =  useMember()

    const [rm, setRm] = useState([])
    

    const {
      form,
      id,
      referrer,
      status
    } = row

    

    form.name = [form?.prefix?? "", form['first-name'], form['last-name'], form?.suffix ?? ""].join(' ')
    form.spouseName = [form?.['spouse-partner-first-name'], form?.['spouse-partner-last-name']].join(' ')
    if (form.spouseName === " ") {form.spouseName = null}

    const {
      email,
      "mobile-number": phone,
      photo
    } = form 

    const efPrimary = [
      { id: 'name', name: 'Name'}, 
      { id: 'address', name: 'Address'}, 
      { id: 'birthday', name: 'Primary Birthday'}, 
      { id: 'job-title', name: 'Career'}, 
      { id: 'company', name: 'Company'}, 
      { id: 'dietary-restrictions', name: 'Primary Dietaries'}, 
      { id: 'gender', name: 'Gender'}, 
      { id: 'job-title', name: 'Job Title'}, 
      { id: 'spouseName', name: 'Spouse Name'}, 
      { id: 'spouse-birthday', name: 'Spouse Birthday'}, 
      { id: 'spouse-partner-email', name: 'Spouse Email'}, 
      { id: 'spouse-partner-phone', name: 'Spouse Phone'}, 
      { id: 'spouse-birthday', name: 'Spouse Birthday'}, 
      { id: 'dietary-restrictions', name: 'Dietaries'}, 
      { id: 'spouse-partner-dietary', name: 'Spouse Dietaries'},
      { id: 'delivery-notes', name: 'Delivery Notes'},
      { id: 'bio', name: 'Artist Bio'},
      { id: 'website', name: 'Website or Social'}
  ]

    const efPersonal = [
      {id: 'artists', question: "Favorite visual artists?"},
      {id: 'authors-books', question: "Favorite authors or books?"},
      {id: 'career', question: "What is your profession?"},
      {id: 'exciting-membership-aspects', question: "What do you consider the most exciting or important aspects of membership (i.e., new experiences, social connections, networking, fine dining, artful living, etc.)?"},
      {id: 'interests-hobbies', question: "What are your current interests?"},
      {id: 'musicians', question: "Favorite musicians?"},
      {id: 'occasions', question: "Do you have any anniversaries or special occasions you like to celebrate? If so, when?"},
      {id: 'passions', question: "What are you most passionate about?"},
      {id: 'pet-peeves', question: "What are some of your biggest Pet Peeves?"}, 
      {id: 'places-to-visit', question: "Favorite places to visit?"}, 
      {id: 'profession', question: "What is your profession?"}, 
      {id: 'restaurants', question: "Favorite restaurants? (Anywhere!)"}, 
      {id: 'snowy-day-order', question: "You walk up to the bar on a beautiful winter day, you order….?"}, 
      {id: 'sunny-day-order', question: "You walk up to the bar on a beautiful sunny day, you order….?"}, 
      {id: 'where-from', question: "How long have you lived in Lexington / if a transplant, where are you from?"}
    ]

    const FieldList = (i) => {
      const input = i.i
      var title = input?.split('-') ?? title
      title = title?.join(' ').toUpperCase() ?? title
      return (
        <Box sx={{textAlign: 'left', }}>
          <Typography variant="h6">{title}:</Typography>
          <Typography>test</Typography>
          <br></br>
          <Divider />
          <br></br>
        </Box>
      )
    }
    
    function BasicModal({img}) {
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
    
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: 10,
          }}>
          <Button size="large" variant="contained" sx={{marginTop: 1}} onClick={handleOpen}>Fullscreen</Button>
          <Button variant="contained" onClick={e=> saveAs(row.form.photo)}>Download Photo</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle} onClick={handleClose}>
              <img src={img} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img>
            </Box>
          </Modal>
        </div>
      );
    }

    function PlanModal({img}) {
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
    
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          }}>
          <Button size="large" variant="contained" sx={{marginTop: 1}} onClick={handleOpen}>IMPORT TO MEMBERSTACK</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={planModalStyle} onClick={handleClose}>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" color="secondary" onClick={e => addMember(e, row, member, "s", callback)}>Send</Button>
              </Stack>
            </Box>
          </Modal>
        </div>
      );
    }
    

    useEffect(() => {

      const referringMember = async () => {
        setRm(await GetMember(member  , referrer))
      }

      console.log('opened app', row)

      referringMember().catch(e => console.log(e))

    }, [member])




    return (
      <Box sx={{backgroundColor: '#c2c2c2'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs sx={{backgroundColor: 'white'}} value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
            <Tab label="Primary Details" {...a11yProps(0)} />
            <Tab label="Personal Details" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Card sx={{ minWidth: 275, height: '100%', width: '0%' }}>
                  <CardContent>
                    <Avatar src={photo} sx={{ width: 250, height: 250 }}></Avatar>
                    <BasicModal img={photo}></BasicModal>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: 275, height: '100%', width: '100%' }}>
                  <CardContent>
                    <Stack direction='row' spacing={2} divider={<Divider orientation="vertical" flexItem />} alignItems='center' alignContent='center'>
                        <Typography variant="h6">{form.name}</Typography>
                        <Typography variant="h6">Email: {email}</Typography>
                        <Typography variant="h6">Phone: {phone}</Typography>
                        <PlanModal />        
                    </Stack>
                    <br></br>
                    <TextField label="Internal Notes" fullWidth multiline rows={4} defaultValue={form.details ?? ''} onChange={v => {row.form.details = v.target.value; callback(row, member?.id)}}/>
                    <br></br>
                    <br></br>
                    <Typography variant="h6">Referred By:</Typography>
                    <br></br>
                    <Stack direction='row' spacing={2} divider={<Divider orientation="vertical" flexItem />} alignItems='center' alignContent='center'>
                        <Avatar src={rm.profileImage}></Avatar>
                        <Typography variant="h6">{rm?.customFields?.['first-name'] ?? ""} {rm?.customFields?.['last-name']}</Typography>
                        <Typography variant="h6">{rm?.auth?.email}</Typography>
                        <Typography variant="h6">{rm?.customFields?.['mobile-number']}</Typography>
                        <Button variant="contained" href={`/members?member=${rm?.id}`}>Open Member</Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
              <Card sx={{paddingTop: 1}}>
                <CardContent>
                  <Typography variant="h3">Primary Information</Typography>
                  <br></br>
                  {efPrimary.map(ef => {
                    if (form[ef.id]) {
                      return (
                        <Stack>
                          <Divider/>
                          <br></br>
                          <Typography>{ef.name}</Typography>
                          <Typography variant="h4">{form[ef.id] ?? 'No Response'}</Typography>
                          <br></br>
                        </Stack>
                      )
                    } else {
                      return (
                        <Stack>
                          <Divider/>
                          <br></br>
                          <Typography>{ef.name}</Typography>
                          <Typography variant="h4">DNR</Typography>
                          <br></br>
                        </Stack>
                      )
                    }

                  })}
                </CardContent>
              </Card>
          </Stack>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Box sx={{backgroundColor: '#fff', padding: 3, borderRadius: 4}}>
            <Typography variant="h5">Personal Questionnaire</Typography>
            <br></br>
            {efPersonal.map(ef => {
              if (form?.[ef.id]) {
                return (
                  <Stack>
                    <Divider/>
                    <br></br>
                    <Typography>{ef.question}</Typography>
                    <Typography variant="h4">{form?.[ef.id] ?? 'No Response'}</Typography>
                    <br></br>
                  </Stack>
                  )
              } else {
                return ""
              }
            }
            )}
          </Box>
        </CustomTabPanel>
      </Box>
    )
  }

export function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text,
  }));

  async function addMember(event, row, member, plan, callback) {

    
    const res = await fetch('https://api.goldenskep.com/api/member/add', {
      method: "POST",
      headers: {
        "X-Member-Id": member?.id
      },
      body: JSON.stringify(row)
    })

    const msRes = await res.json()

    const newMember = JSON.parse(msRes)

    console.log(newMember.response)

    if (newMember?.response?.data?.id) {
      window.open(`https://app.memberstack.com/apps/app_clafnoeep00520uj3cs8wafij/members/${newMember.response.data.id}/profile`)

      row.status = "IMPORTED TO MEMBERSTACK"; callback(row, member?.id)

    } else if (newMember?.response?.code) {
      alert(`Memberstack reported an error: ${newMember?.response?.code}`)
    }
    else {
      alert("There was an unexpected error. Please manually add this member or check to see if they already exist.")
    }

  }





  

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '88vw',
    height: '88vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const planModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
  };

  async function GetMember(member, referrer) {
    let data
    await fetch('https://api.goldenskep.com/api/member/' + referrer, {headers: {'X-Member-Id': member?.id}})
    .then((r) => r.json())
    .then((r) => data = r)
    const cf = data.data.customFields
    const prefix = cf.prefix ?? ''
    const firstName = cf['first-name'] ?? ''
    const lastName = cf['last-name'] ?? ''
    const suffix = cf.suffix ?? ''
    data.data.name = [prefix, firstName, lastName, suffix].join(' ')
    return data.data
  }
  