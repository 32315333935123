

import { Avatar, Box, Divider, Stack, Typography, useMediaQuery} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from "moment/moment";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { ColumnComponent } from "../pages/OrderManagement";


export function OrderDetailsPanel(params) {

    const {row, authID, callback } = params
    const memberId = row.customData[0]?.textInput ?? ''
    const { orderId, status, acceptedOn, purchasedItems} = row

    const [open, setOpen] = useState(false);
    const cc = new ColumnComponent()

    var hiddenCols = {
        data: false,
    }
    

    const apiRef = useGridApiRef()

    const [member, setMember] = useState([{variantId: 1}])
    const cf = member.customFields ?? ''

    if (useMediaQuery('(max-width:700px)')) {
        console.log('going mobile')
        hiddenCols = { 
            data: false,
            status: false,
            id: false, 

         }
    }


    const statuses = [
        { title: 'EN ROUTE', color: '#e8e295' }, 
        { title: 'ON PREM', color: '#4ECDC4' }, 
        { title: 'PACKED', color: '#7EE081' },
        { title: 'UPSTAIRS', color: '#F0B67F' },
        { title: 'FULFILLED', color: '#62A87C' },
        { title: 'BILLED', color: '#D9E5D6' },
        { title: 'REMOVED', color: '#ff2b4b' },
      ]

    const [rows, setDetailRows] = useState([{id: 1}])
    const columns = [
        {
            field: 'id',
        },
        {
            field: 'count',
            headerName: 'Quantity',
            type: 'number',
            width: 100
        },
        {
            field: 'totalBottles',
            headerName: 'Total Bottles',
            type: 'number',
            width: 100,
            valueGetter: (r) => {
                const p = r.row.variantName?.split('Provisions: ')[1]?.split(' ')[0] ?? 'Single'
                if (p === 'Single') {return r.row.count}
                if (p === 'Half') {return r.row.count * 6}
                if (p === 'Case') {return r.row.count * 12}
                else{ return 'N/A'}

            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            editable: true,
            renderCell: (o) => {
                const status = statuses.find((s) => s.title === o.value) ?? {title: 'Not Set', color:'primary'}

                return cc.filledChip(status.title, status.color)
            },
            renderEditCell: (o) => cc.select(o, statuses, open, (e) => {
                const { value } = e.target
                const { id } = o

                apiRef.current.setEditCellValue({ id, field: 'status', value, debounceMs: 200 })
                apiRef.current.stopCellEditMode({ id, field: 'status' });

                const newRows = rows.map(r => {
                    r.status = value
                    console.log(r)
                    return r
                })

                setDetailRows(newRows)

                console.log(rows)
                
            })
        },
        {
            field: 'productName',
            headerName: 'Name',
            flex:1
        },
        {
            field: 'provisions',
            headerName: 'Provisions',
            flex:1,
            valueGetter: (r) => r.row.variantName?.split('Provisions: ')[1] ?? 'Single'
        },
    ]


    const loadMember = async () => {
        const res = await GetMember(authID, memberId)
        setMember(res)
    }


    useEffect(() => {
        loadMember()
        for (let i of purchasedItems) {i.id = i.variantId}
        setDetailRows(purchasedItems)
    }, [])


    return (
      <Box sx={{backgroundColor: '#c2c2c2', padding: 1}}>

        <Stack spacing={1}>
            <CardItem>
                <Stack direction='row' spacing={2} divider={<Divider orientation="vertical" flexItem />} alignItems='center' alignContent='center'>
                        <Avatar src={member.profileImage}></Avatar>
                        <Typography variant="h6">{member.name}</Typography>
                        <Typography variant="h6">{member.auth?.email}</Typography>
                        <Typography variant="h6">{cf['mobile-phone']}</Typography>
                        
                </Stack>
            </CardItem>
            <CardItem>
                <Stack direction='row' spacing={2} divider={<Divider orientation="vertical" flexItem />} alignItems='center' alignContent='center'>
                        <Typography variant="h6">Order ID: {orderId}</Typography>
                        <Typography variant="h6">{status}</Typography>
                        <Typography variant="h6">{moment(new Date(acceptedOn)).format('MM/DD/YYYY')}</Typography>
                        
                </Stack>
            </CardItem>
            <CardItem>
                <DataGridPro
                    {...{rows, columns}}
                    apiRef={apiRef}
                    autoHeight
                    initialState={{
                        columns: {
                        columnVisibilityModel: hiddenCols
                        },
                    }}
                    processRowUpdate={(updated, original) => callback(updated, row, memberId)}
                    onProcessRowUpdateError={(e) => console.log(e)}
                    onCellClick={(params, event, details) => {
                        if (params.field === 'status' && params.cellMode === 'view') {
                        params.open = true
                        apiRef.current.startCellEditMode({ id: params.id, field: 'status' });
                        setOpen(true)
                        }
                    }}
                />
            </CardItem>
        </Stack>


      </Box>
    )
  }




  const CardItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text,
  }));

  const StackItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



  export async function GetMember(authID, member) {
    let data
    await fetch('https://api.goldenskep.com/api/member/' + member, {headers: {'X-Member-Id':'mem_clagwzzt900cw0uj32qy8fscb'}})
    .then((r) => r.json())
    .then((r) => data = r)
    const cf = data.data.customFields
    const prefix = cf.prefix ?? ''
    const firstName = cf['first-name'] ?? ''
    const lastName = cf['last-name'] ?? ''
    const suffix = cf.suffix ?? ''
    data.data.name = [prefix, firstName, lastName, suffix].join(' ')
    return data.data
  }